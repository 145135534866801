<template>
  <nav aria-label="Page navigation">
    <ul class="pagination">
      <!-- Botão Anterior -->
      <li class="page-item" :class="{ disabled: currentPage <= 1 }">
        <button class="page-link" @click="changePage(currentPage - 1)">Anterior</button>
      </li>

      <!-- Números das Páginas -->
      <!-- Mostra somente até 10 números de páginas -->
      <li class="page-item" v-for="page in paginationRange" :class="{ active: page === currentPage }" :key="page">
        <button class="page-link" @click="changePage(page)">{{ page }}</button>
      </li>

      <!-- Botão Próximo -->
      <li class="page-item" :class="{ disabled: currentPage >= totalPages }">
        <button class="page-link" @click="changePage(currentPage + 1)">Próximo</button>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'GamePagination',
  props: {
    currentPage: {
      type: Number,
      required: true
    },
    totalPages: {
      type: Number,
      required: true
    }
  },
  computed: {
    // Cria um intervalo de números de página para a paginação
    paginationRange() {
      const rangeSize = 10;
      const start = Math.max(this.currentPage - Math.floor(rangeSize / 2), 1);
      const end = Math.min(start + rangeSize - 1, this.totalPages);

      // Cria um array com o intervalo de páginas [start, ..., end]
      return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    }
  },
  methods: {
    changePage(page) {
      if (page > 0 && page <= this.totalPages) {
        this.$emit('page-changed', page);
      }
    }
  }
};
</script>

<style scoped>
.pagination {
  display: flex;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  font-size: 0.9rem;
}

.page-item {
  margin: 0 5px;
}

.page-item.disabled .page-link,
.page-item.disabled .page-link:hover {
  color: grey;
  pointer-events: none;
}

.page-link {
  color: white;
  text-decoration: none;
  border: 1px solid transparent;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: color 0.2s, background-color 0.2s;
}

.page-link:hover {
  background-color: #495057; /* Um tom mais claro para o hover */
}

.page-item.active .page-link {
  background-color: #383838; /* Cor azul para o item da página ativa */
  border-color: #282828;
}

@media (max-width: 768px) {
  .pagination {
    font-size: 0.85rem; /* Tamanho de fonte menor para dispositivos móveis */
    flex-wrap: wrap; /* Permite quebra de linha em telas menores */
  }

  .page-item {
    margin: 3px 2px; /* Margens ajustadas para dispositivos móveis */
  }

  .page-link {
    padding: 5px 7px; /* Padding ajustado para dispositivos móveis */
  }
}

@media (max-width: 480px) {
  .pagination {
    font-size: 0.75rem; /* Tamanho de fonte menor para dispositivos muito pequenos */
  }

  .page-link {
    padding: 4px 6px; /* Padding ajustado para dispositivos muito pequenos */
  }
}
</style>
