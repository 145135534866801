<template>
  <div class="upcoming-matches-container">
    <h2 class="upcoming-matches-title">Próximas Partidas</h2>
    <div class="match-cards-container">
      <VueSpinner v-if="isLoading" class="spinner-container"/>
      <div v-if="!isLoading && matches.length">
        <div class="match-card" v-for="match in matches" :key="match.id">
          <div class="match-teams">{{ match.players }}</div>
          <div class="match-time">{{ match.time }}</div>
          <div class="match-h2h">H2H: {{ match.h2h }}</div>
          <div class="match-total">Total: {{ match.totalMatches }}</div>
          <div class="match-bots" v-if="match.bots.length">
            <template v-for="(bot, index) in match.bots" :key="index">
              <BotBadge :botName="bot" />
            </template>
          </div>
        </div>
      </div>
      <div v-if="!isLoading && !matches.length" class="no-results">
        Não há calls previstas no momento.
      </div>
    </div>
  </div>
</template>

<script>
import { VueSpinner } from 'vue3-spinners';
import { fetchNextMatches } from '@/services/matchesService';
import BotBadge from '@/components/BotBadge.vue'; // Importa o novo componente

export default {
  name: 'UpcomingMatchesCard',
  components: {
    VueSpinner, // Registra o VueSpinner aqui
    BotBadge // Registra o BotBadge aqui
  },
  data() {
    return {
      matches: [],
      interval: null, // Vamos guardar a referência do intervalo aqui
      isLoading: false, // Variável para controlar o estado de carregamento
    };
  },
  mounted() {
    this.fetchMatches(); // Iniciar a chamada de fetchMatches
    this.interval = setInterval(this.fetchMatches, 120000); // Configura o intervalo para chamar fetchMatches a cada 120 segundos
  },
  beforeUnmount() {
    clearInterval(this.interval); // Limpar o intervalo ao desmontar o componente
  },
  methods: {
    async fetchMatches() {
      try {
        this.isLoading = true; // Ativar spinner
        const data = await fetchNextMatches();
        if (data && data.next_matches) {
          // Adaptação dos dados recebidos para a estrutura esperada pelo template
          this.matches = data.next_matches.map(match => {
            // Extrair a data e hora do match.data no formato "13/07 04:46"
            const matchData = match.data;
            const [datePart, timePart] = matchData.split(' ');
            const [day, month] = datePart.split('/');
            const [hours, minutes] = timePart.split(':');
            const now = new Date();
            const matchTime = new Date(now.getFullYear(), month - 1, day, hours, minutes);
            const formattedTime = matchTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });

            return {
              data: match.data,
              players: match.players,
              h2h: match.h2h,
              totalMatches: match.Total, // Ajustando o nome para seguir as convenções do JavaScript
              bots: match.bots, // Aqui, assumimos que o backend retorna um array de bots
              time: formattedTime // Adicionando a hora formatada
            };
          });
        }

      } catch (error) {
        console.error('Erro ao buscar próximas partidas:', error);
      } finally {
        this.isLoading = false; // Desativar spinner
      }
    }
  }
};
</script>

<style scoped>
.upcoming-matches-container {
  background-color: #383838;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  color: white;
  height: 45vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.upcoming-matches-title {
  color: var(--color-text);
  text-align: center;
}

.match-cards-container {
  max-height: 35vh;
  overflow-y: auto;
  flex-grow: 1; /* Permite que o contêiner cresça para ocupar o espaço restante */
  position: relative; /* Adiciona posição relativa para o spinner */
}

.match-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: linear-gradient(to bottom right, var(--color-background-light), var(--color-background-dark));
  color: #fff;
  margin: 0.5rem 0;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease;
  margin-bottom: 1rem;
  position: relative;
  flex-wrap: nowrap; /* Evitar quebra de linha */
}
.match-card:hover {
  transform: translateY(-3px);
}
.match-time {
  font-size: 0.8rem;
  color: #7f8c8d;
  margin-right: 1rem;
}

.match-teams {
  color: var(--color-win);
  font-size: 15px;
  font-weight: 700;
}

.match-h2h {
  font-size: 0.8rem;
  color: #7f8c8d;
}

.match-total {
  font-size: 0.8rem;
  color: #7f8c8d;
}

.match-bots {
  display: flex;
  flex-wrap: nowrap; /* Evitar quebra de linha */
  align-items: center;
}

.bot-badge {
  font-weight: bold;
  font-size: 0.8rem; /* Tamanho menor */
  color: #126E51;
  border: 1px solid #126E51; /* Borda discreta */
  text-transform: uppercase; /* Texto em maiúsculas */
  padding: 0 4px; /* Espaçamento interno menor */
  margin: 0 2px; /* Espaçamento externo menor */
}

.match-cards-container::-webkit-scrollbar {
  width: 10px;
}

.match-cards-container::-webkit-scrollbar-track {
  background: #383838;
}

.match-cards-container::-webkit-scrollbar-thumb {
  background: #1abc9c;
  border-radius: 5px;
}

.match-cards-container::-webkit-scrollbar-thumb:hover {
  background: #05846b;
}

.svg-icon {
  margin: 0;
  padding: 0;
  height: 2rem;
  width: auto;
  vertical-align: middle;
}

.no-results {
  color: #ccc;
  text-align: center;
  padding: 20px;
}

.spinner-container {
  position: absolute;
  top: 30%;
  left: 43%;
  transform: translate(-50%, -50%); /* Centraliza o spinner */
}

.vue-spinner {
  font-size: 4rem;
  color: var(--color-win);
}

</style>
