<template>
  <div class="bet-container" @click="toggleExpand">
    <div class="bet-item" :class="{ 'bet-item-expanded': isExpanded }">
      <div class="bet-header">
        <div class="bet-info-container times-container">
          <span class="bet-time">{{ game.times }}</span>
          <div class="svg-container">
            <template v-for="(bot, index) in game.notifications_bots" :key="index">
              <BotBadge :botName="bot" />
            </template>
          </div>
        </div>
        <div class="bet-info-container data-before-final-container">
          <span class="bet-date">Data: {{ game.data }}</span>
          <span class="bet-before">Antes: {{ game.antes }}</span>
          <span class="bet-final-score">Final: {{ game.placar }}</span>
        </div>
        <div class="bet-info-container status-container">
          <span class="bet-status status-lost" v-if="game.status === false">Perdida</span>
          <span class="bet-status status-won" v-if="game.status === true">Retorno Obtido</span>
        </div>
      </div>
      <div class="expanded-section" v-if="isExpanded">
        <div class="expanded-row general-info">
          <span class="expanded-info">Score do {{ game.player_home }} dia: {{ game.score_home_today }}</span>
          <span class="expanded-info">Score do {{ game.player_away }} dia: {{ game.score_away_today }}</span>
        </div>
        <div class="expanded-row month-h2h-info">
          <span class="expanded-info">Score do {{ game.player_home }} mês: {{ game.score_home_month }}</span>
          <span class="expanded-info">Score do {{ game.player_away }} mês: {{ game.score_away_month }}</span>
        </div>
        <div class="expanded-row team-goals-info">
          <span class="expanded-info">Minuto dos Gols: {{ game.time_gols }}</span>
          <span class="expanded-info">Score Head to Head: {{ game.score_h2h }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BotBadge from '@/components/BotBadge.vue'; // Importa o novo componente

export default {
  name: 'GameRow',
  components: {
    BotBadge // Registra o BotBadge aqui
  },
  props: {
    game: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  methods: {
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
    },
  },
};
</script>

<style scoped>
/* Container principal da aposta */
.bet-container {
  display: flex;
  transition: transform var(--transition-speed) ease;
  cursor: pointer;
}

/* Item de aposta */
.bet-item {
  background: linear-gradient(to bottom right, var(--color-background-light), var(--color-background-dark));
  border-radius: var(--border-radius);
  margin-bottom: 10px;
  overflow: hidden; /* Para conter o conteúdo dentro dos cantos arredondados */
  width: 100%;
  padding: 5px;
}

/* Cabeçalho do item da aposta */
.bet-header {
  display: flex;
  width: 100%;
  justify-content: space-between; /* Isso fará com que o .times-container fique à esquerda e os outros itens à direita */
  max-height: 3rem;
  flex-wrap: wrap; /* Permite que os itens quebrem em telas menores */
}
.bet-info-container {
  display: flex;
  align-items: center;
  flex: 1; /* Flexível para ajustar conforme necessário */
  margin-bottom: 5px; /* Espaço entre as linhas em telas menores */
}

.status-container {
  flex: 1 0 10%;
  margin-left: 8px;
  text-align: center;
}

.times-container {
  white-space: nowrap;
  flex: 1 0 50%;
  justify-content: flex-start;
}

.bet-date,
.bet-before,
.bet-final-score {
  flex-grow: 10;
  flex-shrink: 1;
  flex-basis: 0;
  text-align: center;
}

.data-before-final-container {
  display: flex;
  flex: 1 1 30%;
  justify-content: space-between;
}

.bet-date,
.bet-before,
.bet-final-score {
  flex: 1;
  text-align: center;
}

.bet-final-score {
  margin-right: 1.45rem;
}

.bet-time {
  color: var(--color-win);
  font-size: 0.9rem;
  font-weight: 700;
  padding: 0.4rem;
  white-space: nowrap;
}

.bet-date,
.bet-before,
.bet-final-score {
  color: var(--color-text);
  font-size: 0.9rem;
}

.expanded-row {
  color: #ddd;
  font-size: 0.9rem;
  flex-basis: 33%;
}

.expanded-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 0 10px;
}

.bet-status {
  font-size: 0.7rem;
  border-radius: var(--border-radius);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  padding: 0.24rem;
}
.status-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0.24rem;
  flex-grow: 1;
}

.status-lost {
  color: var(--color-lose);
  border: 1px solid #555;
}

.status-won {
  color: var(--color-win);
  font-weight: 600;
  border: 1px solid #555;
}

.expanded-section {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-top: 5px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 0.8rem;
}
.expanded-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap;
}
.expanded-info {
  flex: 1;
  text-align: left;
  margin-left: 20px;
}

.form-container-wrapper {
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.expanded-row:last-child {
  margin-bottom: 0;
}

.svg-container {
  display: flex;
  align-items: center;
  height: 1rem;
  margin-right: 2rem;
}

.svg-icon {
  margin: 0;
  padding: 0;
  height: 2rem;
  width: auto;
  vertical-align: middle;
}

/* Estilos responsivos */
@media (max-width: 768px) {
  .bet-header {
    flex-direction: column;
    align-items: flex-start;
  }
  .data-before-final-container {
    flex-direction: column;
    align-items: flex-start;
  }
  .status-container {
    margin-left: 0;
  }
  .bet-info-container {
    width: 100%;
  }
  .data-before-final-container span {
    display: block;
    margin: 5px 0;
  }
  .bet-time {
    font-size: 0.7rem;
  }
  .bet-date,
  .bet-before,
  .bet-final-score {
    font-size: 0.5rem;
  }
  .expanded-row {
    flex-direction: column;
    align-items: flex-start;
  }
  .expanded-info {
    margin-left: 10px;
  }
}

/* Estilos responsivos para telas muito pequenas */
@media (max-width: 480px) {
  .bet-item {
    padding: 10px; /* Aumenta o padding para telas muito pequenas */
  }
  .bet-header {
    max-height: none; /* Permite que o header tenha altura variável */
    flex-direction: column; /* Organiza itens em coluna */
  }
  .bet-info-container {
    flex-direction: column; /* Reorganiza os itens verticalmente */
    align-items: flex-start;
  }
  .times-container {
    flex-direction: row;
    width: 100%; /* Ocupa 100% da largura */
    font-size: 1rem; /* Aumenta o tamanho da fonte */
    font-weight: bold;
    margin-bottom: 5px; /* Espaço abaixo do título */
  }
  .data-before-final-container {
    flex-direction: row; /* Organiza itens em linha */
    flex-wrap: wrap; /* Permite quebra de linha */
    width: 100%; /* Ocupa 100% da largura */
    justify-content: space-between;
  }
  .bet-date,
  .bet-before,
  .bet-final-score {
    font-size: 0.9rem;
    flex: 1;
    text-align: left;
  }
  .data-before-final-container span {
    margin-right: 10px; /* Espaço entre os elementos */
  }
  .status-container {
    flex-direction: row;
    justify-content: flex-start;
  }
}
</style>
