// src/services/rankingService.js
const API_BASE_URL = 'https://api.dale365.com/api/v1/ranking/';

async function fetchFromApi(endpoint, options = {}) {
  const url = `${API_BASE_URL}${endpoint}`;
  const defaultHeaders = {
    'Content-Type': 'application/json',
    'Authorization': `Token ${localStorage.getItem('userToken')}`
  };

  const config = {
    ...options,
    headers: {
      ...defaultHeaders,
      ...options.headers
    }
  };

  try {
    const response = await fetch(url, config);
    if (response.status === 401) {
      const data = await response.json();
      if (data.detail === 'Invalid token.') {
        // Deslogar usuário e redirecionar para a página de login
        localStorage.removeItem('userToken');
        localStorage.setItem('userLoggedIn', 'false');
        window.location.href = '/login';
      }
    }
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Erro desconhecido');
    }
    // If the response status is 204 (No Content), return null
    if (response.status === 204) {
      return null;
    }
    return await response.json();
  } catch (error) {
    console.error('Erro ao fazer requisição para a API:', error);
    throw error;
  }
}

export function fetchTopPlayers(rankingType) {
  const endpoint = `today-stats/?id=1&rankingType=${rankingType}`;
  return fetchFromApi(endpoint);
}

export function fetchRankingPlayers(endpointKey) {
  const endpointMap = {
    'overall-1': 'players?id=1&today=false',
    'overall-3': 'players?id=3&today=false',
    'daily-1': 'players?id=1&today=true',
    'daily-3': 'players?id=3&today=true',
    'overall-h2h-1': 'h2h?id=1&today=false',
    'overall-h2h-3': 'h2h?id=3&today=false',
    'daily-h2h-1': 'h2h?id=1&today=true',
    'daily-h2h-3': 'h2h?id=3&today=true',
  };
  return fetchFromApi(endpointMap[endpointKey]);
}
