<template>
  <div class="top-balances-ranking">
    <div class="user-list">
      <div class="user-item" v-for="user in paginatedUsers" :key="user.position">
        <div class="user-rank-and-info">
          <span v-if="user.position === 1" class="medal-icon">
            <i class="fas fa-medal"></i>
          </span>
          <div class="position">{{ user.position }}.</div>
          <div class="name">{{ user.name }}</div>
          <div class="balance" v-if="hasPermission && user.balance !== undefined">{{ formatCurrency(user.balance) }}</div>
        </div>
      </div>
    </div>
    <div class="pagination">
      <button @click="prevPage" :disabled="currentPage === 1">Anterior</button>
      <span class="page-info">Página {{ currentPage }} de {{ totalPages }}</span>
      <button @click="nextPage" :disabled="currentPage === totalPages">Próxima</button>
    </div>
  </div>
</template>


<script>
export default {
  props: {
    users: {
      type: Array,
      default: () => []
    },
    hasPermission: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentPage: 1,
      itemsPerPage: 10
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.sortedUsers.length / this.itemsPerPage);
    },
    paginatedUsers() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.sortedUsers.slice(start, end);
    },
    sortedUsers() {
      if (!Array.isArray(this.users)) {
        return [];
      }
      return this.users.slice().sort((a, b) => b.balance - a.balance);
    }
  },
  methods: {
    formatCurrency(value) {
      if (!value) return 'R$ 0,00';
      return `R$ ${value.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    }
  }
};
</script>


<style scoped>
.top-balances-ranking {
  background-color: #383838;
  border-radius: 8px;
  padding: 20px;
  color: #ccc;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; /* Faz o componente ocupar toda a largura disponível */
}

.user-list {
  width: 100%;
  margin-bottom: 20px;
}

.user-item {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #555;
  width: 100%; /* Faz o item de usuário ocupar toda a largura disponível */
}

.user-rank-and-info {
  display: flex;
  align-items: center;
  width: 100%; /* Faz a informação do usuário ocupar toda a largura disponível */
  justify-content: space-between; /* Justificar o conteúdo para ocupar todo o espaço */
}

.medal-icon {
  font-size: 1rem;
  margin-right: 10px;
  color: #FFD700; /* Gold color */
}

.position {
  font-size: 0.8rem;
  margin-right: 5px;
  color: #FFD700;
}

.name {
  font-size: 0.9rem;
  font-weight: bold;
  color: #fff;
  margin-right: auto; /* Use margin-right: auto to push the balance to the right */
}

.balance {
  font-size: 0.9rem;
  color: #26ffbe;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.pagination button {
  background-color: #4a4a4a;
  color: #ccc;
  border: none;
  padding: 5px 10px;  /* Smaller padding for smaller buttons */
  margin: 0 5px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  font-size: 0.8rem; /* Smaller font size for buttons */
}

.pagination button:disabled {
  background-color: #282828;
  cursor: not-allowed;
}

.pagination button:hover:not(:disabled) {
  background-color: #5a5a5a;
}

.page-info {
  font-size: 0.8rem; /* Smaller font size for page info */
  color: #ccc;
}

@media (max-width: 1200px) {
  .user-rank-and-info {
    flex-direction: row; /* Garantir que as informações do usuário estejam em linha para telas menores */
    align-items: center;
  }

  .name {
    margin-right: auto; /* Manter a margem direita para empurrar o saldo à direita */
  }

  .balance {
    align-self: flex-end; /* Alinhar o saldo à direita */
  }

  .pagination {
    flex-direction: column;
    gap: 10px;
  }

  .pagination button {
    width: 100%;
  }
}
</style>
