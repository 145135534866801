<template>
  <div class="bets-table-wrapper">
    <div class="controls-container">
      <select class="ranking-select" v-model="localSelectedOption" @change="onSelectionChange">
        <option value="matches">Todas as Partidas</option>
        <option value="draws">Somente Empates</option>
        <option v-for="bot in botPermissions" :key="bot.id" :value="bot.id">{{ bot.name }}</option>
      </select>
      <div class="search-container">
        <input type="text" class="status-search" placeholder="Pesquisar..." v-model="searchTerm" />
        <div class="search-icon"></div>
      </div>
    </div>
    <VueSpinner v-if="isLoading" class="spinner-container"/>
    <div v-else>
      <div v-if="filteredGames.length">
        <GameRow v-for="game in filteredGames" :key="game.id" :game="game" />
      </div>
      <div v-else class="no-results">
        Não foram encontrados itens que correspondam à sua pesquisa.
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import GameRow from './GameRow.vue';
import { VueSpinner } from 'vue3-spinners';
import { fetchMatches, fetchDraws, fetchDale } from '@/services/matchesService';

export default {
  name: 'GameTable',
  components: {
    GameRow,
    VueSpinner
  },
  props: {
    selectedOption: {
      type: String,
      default: 'matches'
    }
  },
  data() {
    return {
      games: [],
      searchTerm: '',
      localSelectedOption: this.selectedOption,
      botPermissions: JSON.parse(localStorage.getItem('botPermissions') || '[]'),
      isLoading: false,
      totalPages: 0
    };
  },
  watch: {
    selectedOption(newVal) {
      this.localSelectedOption = newVal;
      this.fetchGamesDebounced(newVal);
    },
    searchTerm: 'onSearchInputDebounced'
  },
  computed: {
    filteredGames() {
      if (!this.games || this.games.length === 0) return [];
      const searchTermLower = this.searchTerm.toLowerCase().split(' ');
      return this.games.filter((game) => {
        return searchTermLower.every(term =>
          game.times.toLowerCase().includes(term) ||
          game.placar.replace(':', 'x').includes(term)
        );
      });
    }
  },
  methods: {
    async fetchGames(option, page = 1) {
      this.isLoading = true;
      try {
        let data;
        if (option === 'matches') {
          data = await fetchMatches(page, this.searchTerm);
        } else if (option === 'draws') {
          data = await fetchDraws(page, this.searchTerm);
        } else {
          data = await fetchDale(option, page, this.searchTerm);
        }
        this.games = data.results || [];
        this.totalPages = Math.ceil(data.count / 50);
        this.$emit('update:isLoading', false);
        this.$emit('update:totalPages', this.totalPages);
      } catch (error) {
        console.error("Erro ao buscar partidas:", error);
      } finally {
        this.isLoading = false;
      }
    },
    fetchGamesDebounced: debounce(function(option) {
      this.fetchGames(option);
    }, 300), // Debounce com 300ms de atraso

    onSearchInputDebounced: debounce(function() {
      this.fetchGames(this.localSelectedOption);
    }, 300), // Debounce com 300ms de atraso

    onSelectionChange(event) {
      const value = event.target.value;
      this.localSelectedOption = value;
      this.fetchGamesDebounced(value);
    }
  },
  mounted() {
    this.fetchGames(this.localSelectedOption);
  }
};
</script>



<style scoped>
.vue-spinner {
  /* Customize aqui, se necessário. Por exemplo: */
  font-size: 4rem; /* Ajusta o tamanho do spinner */
  color: var(--color-win); /* Exemplo de cor, ajuste conforme necessário */
}
.spinner-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  left: 55vh;
  bottom: 15vh;
}
.table-header-fixed {
  position: sticky;
  top: 0;
  background-color: #282828;
  z-index: 5;
}

.bets-table-wrapper {
  overflow-y: auto;
  max-height: 65vh;
  margin-bottom: 2rem;
}

.no-results {
  color: #ccc;
  text-align: center;
  padding: 20px;
}

.bets-table-wrapper::-webkit-scrollbar-track {
  background-color: #282828;
}

.bets-table-wrapper::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.bets-table-wrapper::-webkit-scrollbar {
  width: 8px;
}

.bets-table-wrapper {
  margin-bottom: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  overscroll-behavior: none;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  scrollbar-color: #888 transparent;
  margin-right: -1rem;
  padding-right: 1rem;
  max-height: 80vh;
}

.controls-container {
  display: flex;
  justify-content: space-between; /* Distribui os itens nas extremidades */
  align-items: center; /* Alinha verticalmente ao centro */
  margin-bottom: 1rem; /* Espaço abaixo do container de controles */
}

.search-container {
  display: flex;
  align-items: center;
  position: relative;
  flex-grow: 1; /* Faz com que o campo de pesquisa ocupe o espaço disponível */
  max-width: 50%; /* Ajusta a largura máxima do campo de pesquisa */
}

.status-search {
  width: 100%; /* Ajusta o tamanho do campo de pesquisa */
  font-size: 0.8rem;
  padding: 0.6em 0.8em 0.6em 2.5em; /* Aumente o padding esquerdo para acomodar o ícone */
  border: none;
  border-radius: 20px;
  background-color: #383838;
  color: white;
  font-size: 0.8rem; /* Ajuste o tamanho da fonte conforme necessário */
  outline: none;
  background-image: url('~@/assets/search.svg'); /* Substitua pelo caminho correto do seu ícone */
  background-repeat: no-repeat;
  background-position: 10px center; /* Posiciona o ícone à esquerda */
  background-size: 16px; /* Ajuste o tamanho do ícone conforme necessário */
}

.ranking-select {
  min-width: 3rem; /* Ou qualquer outra largura que você achar adequada */
  width: 14rem;
  -webkit-appearance: none; /* Desabilita o estilo padrão no Chrome e Safari */
  -moz-appearance: none; /* Desabilita o estilo padrão no Firefox */
  appearance: none; /* Desabilita o estilo padrão para outros navegadores */
  position: relative;
  background-image: url('data:image/svg+xml;utf8,<svg fill="white" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>'); /* Ícone de seta para baixo */
  background-repeat: no-repeat;
  background-position: right 10px center; 
  background-size: 12px;
  padding: 0.6em 1em; /* Ajusta o padding para igualar ao input */
  border: none;
  border-radius: 20px;
  background-color: #383838;
  color: white;
  font-size: 0.8rem; /* Ajuste o tamanho da fonte conforme necessário */
}

@media (max-width: 768px) {
  .table-header-fixed {
    font-size: 0.8rem;
    padding: 0.5rem;
  }

  .bets-table-wrapper {
    max-height: 100vh;
    padding-right: 0.5rem;
  }

  .no-results {
    font-size: 0.9rem;
  }
  .controls-container {
    flex-direction: column;
    align-items: stretch;
  }
  .search-container {
    width: 100%;
    margin-top: 1rem;
  }
  .bets-table-wrapper{
    height: 100vh;
  }
  .spinner-container {
    width: 30%;
    height: 30%;
    position: fixed; /* Usa posição fixa para ficar visível mesmo com rolagem */
    margin: 0;
    top: 2rem;
    left: 7rem;
  }
}
</style>
