<template>
  <v-chip
    class="bot-badge"
    outlined
    color="grey lighten-3"
  >
    {{ badgeCharacter }}
  </v-chip>
</template>

<script>
export default {
  name: 'BotBadge',
  props: {
    botName: {
      type: String,
      required: true
    }
  },
  computed: {
    badgeCharacter() {
      const parts = this.botName.split(' ');
      return parts.length > 1 ? parts[1][0] : parts[0][0];
    }
  }
};
</script>

<style scoped>
.bot-badge {
  font-weight: bold;
  font-size: 1rem;
  color: #126E51;
  border: 1px solid #126E51; /* Borda discreta */
  text-transform: uppercase; /* Texto em maiúsculas */
  padding: 0 8px; /* Espaçamento interno */
  margin: 0 4px; /* Espaçamento externo */
}
</style>
