<template>
  <div class="withdraw-card-table">
    <div class="filter-options">
      <div class="filter-row">
        <label for="month">Mês:</label>
        <input type="month" class="month-input" v-model="selectedMonth" @change="fetchData">
        <label for="account">Conta:</label>
        <select class="account-input" v-model="selectedAccount" @change="fetchData">
          <option value="">Todas</option>
          <option v-for="account in accounts" :key="account.id" :value="account.id">{{ account.name }}</option>
        </select>
      </div>
      <div class="filter-row">
        <label class="checkbox-label">
          <input type="checkbox" v-model="groupByAccount" @change="fetchData"> Agrupar por Dia
        </label>
        <label class="total-withdrawals-label">Total Saque: <span class="total-withdrawals-value">{{ formatCurrency(totalWithdrawals) }}</span></label>
      </div>
    </div>
    <table>
      <thead>
        <tr>
          <th @click="sortTable('date')">Dia</th>
          <th v-if="!groupByAccount" @click="sortTable('account__name')">Conta</th>
          <th @click="sortTable('total_withdrawal')">Saque</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="entry in paginatedData" :key="entry.date + (entry.account_id || '')">
          <td>{{ formatDate(entry.date) }}</td>
          <td v-if="!groupByAccount">{{ entry.account__name }}</td>
          <td>{{ formatCurrency(entry.total_withdrawal) }}</td>
        </tr>
      </tbody>
    </table>
    <div class="pagination">
      <button @click="prevPage" :disabled="currentPage === 1">Anterior</button>
      <span class="page-info">Página {{ currentPage }} de {{ totalPages }}</span>
      <button @click="nextPage" :disabled="currentPage === totalPages">Próxima</button>
    </div>
  </div>
</template>



<script>
import { getWithdrawBalances } from '@/services/accountsService';

export default {
  data() {
    return {
      selectedMonth: new Date().toISOString().slice(0, 7),
      selectedAccount: '',
      groupByAccount: false,
      accounts: [],
      data: [],
      filteredData: [],
      currentPage: 1,
      itemsPerPage: 10,
      totalWithdrawals: 0,
      isMobile: window.innerWidth <= 768
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.filteredData.length / this.itemsPerPage);
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredData.slice(start, end);
    }
  },
  methods: {
    formatDate(date) {
      const options = this.isMobile
        ? { day: '2-digit', month: '2-digit' }
        : { day: '2-digit', month: '2-digit', year: 'numeric' };
      return new Date(date).toLocaleDateString('pt-BR', options);
    },
    formatCurrency(value) {
      if (!value) return 'R$ 0,00';
      return `R$ ${value.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;
    },
    async fetchData() {
      const [year, month] = this.selectedMonth.split('-');
      const accountId = this.selectedAccount;
      const groupByAccount = this.groupByAccount;

      try {
        const response = await getWithdrawBalances(month, year, accountId, groupByAccount);
        this.data = response.withdrawals.map(entry => ({
          ...entry,
          date: new Date(entry.date + 'T00:00:00') // Forçar a data para o fuso horário local
        }));
        this.calculateTotalWithdrawals(this.data);
        this.applyFilters();
      } catch (error) {
        console.error("Erro ao buscar dados de saques mensais:", error);
      }
    },
    async fetchAccounts() {
      const [year, month] = this.selectedMonth.split('-');

      try {
        const response = await getWithdrawBalances(month, year);
        const accountsSet = new Set();
        response.withdrawals.forEach(entry => {
          accountsSet.add(JSON.stringify({ id: entry.account_id, name: entry.account__name }));
        });
        this.accounts = Array.from(accountsSet).map(account => JSON.parse(account));
      } catch (error) {
        console.error("Erro ao buscar contas:", error);
      }
    },
    calculateTotalWithdrawals(balances) {
      this.totalWithdrawals = balances.reduce((acc, entry) => acc + entry.total_withdrawal, 0);
    },
    applyFilters() {
      if (this.groupByAccount) {
        const groupedData = this.data.reduce((acc, entry) => {
          const existing = acc.find(item => item.date.getTime() === entry.date.getTime());
          if (existing) {
            existing.total_withdrawal += entry.total_withdrawal;
          } else {
            acc.push({
              date: entry.date,
              total_withdrawal: entry.total_withdrawal
            });
          }
          return acc;
        }, []);
        this.filteredData = groupedData;
      } else {
        this.filteredData = this.data;
      }
      this.sortData();
      this.currentPage = 1;
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    sortTable(key) {
      if (this.sortKey === key) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortKey = key;
        this.sortOrder = 'asc';
      }
      this.sortData();
    },
    sortData() {
      this.filteredData.sort((a, b) => {
        let result = 0;
        if (a[this.sortKey] < b[this.sortKey]) result = -1;
        if (a[this.sortKey] > b[this.sortKey]) result = 1;
        return this.sortOrder === 'asc' ? result : -result;
      });
    },
    updateIsMobile() {
      this.isMobile = window.innerWidth <= 768;
    }
  },
  mounted() {
    this.fetchAccounts();
    this.fetchData();
    window.addEventListener('resize', this.updateIsMobile);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateIsMobile);
  },
  watch: {
    selectedMonth() {
      this.fetchAccounts();
      this.fetchData();
    },
    selectedAccount() {
      this.fetchData();
    }
  }
};
</script>




<style scoped>
.withdraw-card-table {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align items to the top */
  padding-top: 0; /* Remove any top padding */
}

.total-profit-label, .total-withdrawals-label {
  color: #fff;
  font-family: 'Roboto', sans-serif;
  display: flex; /* Alinhar itens na mesma linha */
  align-items: center; /* Alinhar verticalmente */
  margin-left: 15px;
}

.total-profit-value, .total-withdrawals-value {
  font-weight: bold; /* Negrito */
  color: #4caf50; /* Cor verde para o valor */
  margin-left: 5px; /* Espaçamento entre o texto e o valor */
  font-size: 0.8rem;
}

.checkbox-label {
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-weight: bold; /* Negrito */
  display: flex;
  align-items: center;
  margin-right: 10px; /* Espaço entre a checkbox e o próximo elemento */
}

.checkbox-label input[type="checkbox"] {
  margin-right: 5px;
}

.month-input{
  max-width: 30%;
}
.account-input{
  max-width: 40%;
}

h2 {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 1.5rem;
  color: #fff;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif; /* Usando a fonte Poppins */
  letter-spacing: 1px; /* Adiciona um pouco de espaçamento entre as letras */
  font-weight: 600; /* Usando peso da fonte mais pesado para destaque */
}

.filter-options {
  display: flex;
  flex-wrap: wrap; /* Permite que os itens sejam dispostos em múltiplas linhas */
  gap: 10px;
  margin-bottom: 20px;
  background-color: #383838;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.filter-row {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  width: 100%;
}

.filter-options label {
  color: #ccc;
  font-family: 'Roboto', sans-serif;
}

.filter-options input[type="month"],
.filter-options select {
  background-color: #282828;
  color: #fff;
  border: 1px solid #555;
  border-radius: 4px;
  padding: 5px;
}

.filter-options .checkbox-label {
  display: flex;
  align-items: center;
}

.filter-options .checkbox-label input[type="checkbox"] {
  margin-left: 5px;
  margin-right: 5px;
}

table {
  width: 100%;
  border-collapse: collapse;
  background-color: #383838;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

th, td {
  border: 1px solid #555;
  padding: 10px;
  text-align: left;
  color: #ccc;
  white-space: nowrap; /* Evita quebra de linha */
}

th {
  background-color: #4a4a4a;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
}

tbody tr:hover {
  background-color: #4a4a4a;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  background-color: #4a4a4a;
  color: #ccc;
  border: none;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  font-size: 0.8rem;
}

.pagination button:disabled {
  background-color: #282828;
  cursor: not-allowed;
}

.pagination button:hover:not(:disabled) {
  background-color: #5a5a5a;
}

.page-info {
  font-size: 0.8rem;
  color: #ccc;
}

@media (max-width: 1200px) {
  .month-input{
  max-width: 100%;
  }
  .account-input{
    max-width: 100%;
  }
  .filter-options {
    flex-direction: column;
    align-items: flex-start;
    font-size: 0.7rem;
  }

  .filter-row {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  table {
    font-size: 0.7rem;
  }

  .pagination {
    flex-direction: column;
    gap: 10px;
  }

  .pagination button {
    width: 100%;
  }
}
</style>
