<template>
  <router-view />
</template>

<script>
export default {
  name: 'App'
};
</script>

<style>
/* Remove global overflow restrictions */
body, html {
  height: 100%;
}

#app {
  height: 100%;
}

/* Retain mobile-specific styles if needed */
@media (max-width: 768px) {
  body, html, #app {
    height: auto;
  }
}
</style>
