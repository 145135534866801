<template>
  <div class="dashboard">
    <div class="dashboard-section">
      <h2>Saldo</h2>
      <div class="balance-info">
        <p class="value">{{ formatCurrency(totalBalance) }}</p>
        <p class="label"><i class="fas fa-wallet"></i> Total Mensal</p>
      </div>
      <div class="balance-info">
        <p class="value">{{ formatCurrency(averageBalance) }}</p>
        <p class="label"><i class="fas fa-chart-line"></i> Média Diária</p>
      </div>
    </div>
    <div class="dashboard-section">
      <h2>Saques</h2>
      <div class="withdrawals-info">
        <p class="value">{{ formatCurrency(totalMonthlyWithdrawal) }}</p>
        <p class="label"><i class="fas fa-money-bill-wave"></i> Total Mensal</p>
      </div>
      <div class="withdrawals-info">
        <p class="value">{{ formatCurrency(averageAnnualWithdrawal) }}</p>
        <p class="label"><i class="fas fa-calendar-alt"></i> Média Anual</p>
      </div>
    </div>
    <div class="dashboard-section">
      <h2>Depósitos</h2>
      <div class="deposits-info">
        <p class="value">{{ formatCurrency(totalAnnualDeposits) }}</p>
        <p class="label"><i class="fas fa-chart-column"></i> Total Anual</p>
      </div>
      <div class="deposits-info">
        <p class="value">{{ formatCurrency(averageMonthlyDeposits) }}</p>
        <p class="label"><i class="fas fa-calendar-check"></i> Média Mensal</p>
      </div>
    </div>
  </div>
</template>




<script>
export default {
  props: {
    totalBalance: {
      type: Number,
      default: 0
    },
    averageBalance: {
      type: Number,
      default: 0
    },
    totalMonthlyWithdrawal: {
      type: Number,
      default: 0
    },
    averageAnnualWithdrawal: {
      type: Number,
      default: 0
    },
    totalAnnualDeposits: {
      type: Number,
      default: 0
    },
    averageMonthlyDeposits: {
      type: Number,
      default: 0
    }
  },
  methods: {
    formatCurrency(value) {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }).format(value);
    }
  }
};
</script>

<style scoped>
.dashboard {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
  background-color: #282828;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.dashboard-section {
  background-color: #383838;
  padding: 20px;
  border-radius: 8px;
  color: #ccc;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, background-color 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.dashboard-section:hover {
  transform: translateY(-5px);
  background-color: #4a4a4a;
}
h2 {
  margin-bottom: 15px;
  font-size: 1.5rem;
  color: #fff;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif; /* Usando a fonte Poppins */
  letter-spacing: 1px; /* Adiciona um pouco de espaçamento entre as letras */
  font-weight: 600; /* Usando peso da fonte mais pesado para destaque */
}


.value {
  font-size: 1.5rem;
  font-weight: 500; /* Usando peso da fonte Roboto */
  font-family: 'Roboto', sans-serif; /* Usando a fonte Roboto */
  color: #fff;
  margin: 5px 0;
}

.label {
  font-size: 1rem;
  color: #bbb;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto', sans-serif; /* Usando a fonte Roboto */
}
.label i {
  margin-right: 5px;
  font-size: 1rem;
  color: #26ffbe;
}
</style>
