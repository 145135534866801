<template v-if="filteredPlayers && filteredPlayers.length">
  <div class="ranking-container">
    <div class="search-and-select-container">
      <select class="ranking-select" v-model="selectedRankingType" @change="changeRankingType">
        <option value="daily-1">Diário</option>
        <option value="overall-1">Geral</option>
        <option value="daily-h2h-1">Confronto Diário</option>
        <option value="overall-h2h-1">Confronto Geral</option>
        <!-- Adicione mais opções conforme necessário -->
      </select>
      <input type="text" class="status-search" placeholder="Pesquisar no ranking..." @input="filterStatus" />
    </div>
    <div class="ranking-header">
      <span class="ranking-title" @click="toggleSort('name')">Nome</span>
      <span class="ranking-title" @click="toggleSort('R')">Red</span>
      <span class="ranking-title" @click="toggleSort('G')">Green</span>
      <span class="ranking-title" @click="toggleSort('S')">Score</span>
    </div>
    <div class="ranking-body">
      <template v-if="filteredPlayers.length">
        <div v-for="(player, index) in filteredPlayers" :key="index" class="ranking-row" :class="{'negative-score': player.S < 0}">
          <span class="player-name">{{ player.name }}</span>
          <span class="player-score">{{ player.R }}</span>
          <span class="player-games">{{ player.G }}</span>
          <span class="player-s">{{ player.S }}</span>
        </div>
      </template>
      <template v-if="error">
        <div class="no-results">
          Nenhum jogador encontrado para a pesquisa ou ocorreu um erro.
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { fetchRankingPlayers } from '@/services/rankingService';

export default {
  name: 'RankingTable',
  data() {
    return {
      selectedRankingType: 'daily-1',
      players: [],
      searchQuery: '',
      error: false,
      isLoading: false,
      sortColumn: null,
      sortOrder: 'normal',
    };
  },
  methods: {
    async changeRankingType() {
      this.isLoading = true;
      try {
        const data = await fetchRankingPlayers(this.selectedRankingType);
        if (Array.isArray(data.players_rankings)) {
          this.players = data.players_rankings.map(player => ({
            name: player.player_name,
            R: player.reds,
            G: player.greens,
            S: player.score,
          }));
          this.error = false;
        } else {
          console.error('A propriedade players_rankings da resposta da API não é um array', data);
          this.players = [];
        }
      } catch (error) {
        console.error("Erro ao buscar os jogadores:", error);
        if (error.response && error.response.status === 404) {
          this.error = true;
          this.players = [];
        }
      } finally {
        this.isLoading = false;
      }
    },
    filterStatus(event) {
      this.searchQuery = event.target.value;
    },
    toggleSort(column) {
      if (this.sortColumn === column) {
        if (this.sortOrder === 'asc') {
          this.sortOrder = 'desc';
        } else if (this.sortOrder === 'desc') {
          this.sortOrder = 'normal';
        } else {
          this.sortOrder = 'asc';
        }
      } else {
        this.sortColumn = column;
        this.sortOrder = 'asc';
      }
    },
  },
  computed: {
    filteredPlayers() {
      const searchTerms = this.searchQuery.toLowerCase().split(' ');

      let players = this.players.filter(player => {
        const playerWords = player.name.toLowerCase().split(' ');

        return searchTerms.every(term => playerWords.some(word => word.includes(term)));
      });

      if (this.sortOrder !== 'normal' && this.sortColumn) {
        players.sort((a, b) => {
          let valA = a[this.sortColumn];
          let valB = b[this.sortColumn];

          if (this.sortOrder === 'asc') {
            return valA > valB ? 1 : -1;
          } else if (this.sortOrder === 'desc') {
            return valA < valB ? 1 : -1;
          }
        });
      }

      return players;
    },
  },
  watch: {
    selectedRankingType() {
      this.changeRankingType();
    }
  },
  mounted() {
    this.changeRankingType();
  }
};
</script>

<style scoped>
.ranking-container {
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  padding: 0.4rem;
  color: var(--color-text);
  font-size: 0.9rem;
}

.ranking-header, .ranking-row {
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #282828;
}

.player-name {
  color: var(--color-win);
  font-weight: 700;
  flex: 3 1 auto;
}

.ranking-title, .player-name, .player-score, .player-games, .player-s {
  text-align: center;
}

.ranking-title, .player-name {
  flex: 3 0 0;
}

.ranking-title:nth-child(2), .player-score {
  flex: 1 0 0;
  min-width: 13%;
}

.ranking-title:nth-child(3), .player-games {
  flex: 1 0 0;
  min-width: 13%;
}

.ranking-title:nth-child(4), .player-s {
  flex: 1 0 0;
  min-width: 13%;
}

.ranking-title {
  cursor: pointer;
  user-select: none;
}

.ranking-body {
  display: flex;
  flex-direction: column;
  max-height: 66vh;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #888 #333; /* Para Firefox */
}

.ranking-body:hover::-webkit-scrollbar {
  width: 8px;
}

.ranking-body::-webkit-scrollbar {
  width: 0;
}

.ranking-body::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.ranking-body::-webkit-scrollbar-track {
  background: #333;
}

.ranking-row {
  display: flex;
  justify-content: space-between;
  background: linear-gradient(to bottom right, var(--color-background-light), var(--color-background-dark));
  margin-bottom: 10px;
  border-radius: var(--border-radius);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  min-height: 2rem;
  padding: 0.5rem;
  align-items: center;
}

.ranking-row:hover {
  background: var(--color-background-lightest);
}

.negative-score {
  color: var(--color-lose);
}

.search-and-select-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
}

.status-search {
  height: 24%;
  margin: 4px;
  width: 100%;
  font-size: 0.8rem;
  padding: 0.6em 0.8em 0.6em 2.5em;
  border: none;
  border-radius: 20px;
  background-color: #383838;
  color: white;
  font-size: 0.8rem;
  outline: none;
  background-image: url('~@/assets/search.svg');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 16px;
}

.no-results {
  color: #ccc;
  text-align: center;
  padding: 20px;
}

.ranking-header {
  position: sticky;
  top: 0;
  z-index: 1000;
}

.ranking-select {
  margin-top: 1.1rem;
  margin-right: 1rem;
  min-width: 3rem;
  width: 14rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  background-image: url('data:image/svg+xml;utf8,<svg fill="white" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 12px;
  padding: 0.2em 0.1em 0.2em 1em;
  border: none;
  border-radius: 1.5em;
  background-color: #383838;
  color: white;
  outline: none;
  font-size: 0.8rem;
  margin-bottom: 20px;
}

:root {
  --color-background-dark: #333;
  --color-background-light: #444;
  --color-background-lighter: #555;
  --color-background-lightest: #666;
  --color-text: #fff;
  --color-lose: #f00;
  --border-radius: 8px;
  --box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
</style>
