<template>
  <DaleHeader />
  <div class="my-bets-container">
    <div class="bets-content">
      <AccountCard :accountNames="accountNames" @account-changed="fetchAccounts"/>
      <BalanceCard :accountNames="accountNames" @account-changed="fetchAccounts"/>
      <WithdrawCard :accountNames="accountNames" @account-changed="fetchAccounts"/>
    </div>
  </div>
</template>

<script>
import WithdrawCard from '@/components/MyBets/WithdrawCard.vue';
import AccountCard from '@/components/MyBets/AccountCard.vue';
import BalanceCard from '@/components/MyBets/BalanceCard.vue';
import DaleHeader from '../components/Header.vue';
import { getBettingAccounts } from '@/services/bettingService';

export default {
  name: 'MyBets',
  components: {
    DaleHeader,
    AccountCard,
    WithdrawCard,
    BalanceCard,
  },
  data() {
    return {
      accountNames: [],
    };
  },
  methods: {
    handleAddAccount(newAccount) {
      this.accountNames.push(newAccount);
    },
    async fetchAccounts() {
      try {
        const accounts = await getBettingAccounts();
        this.accountNames = accounts;
      } catch (error) {
        console.error('Error fetching accounts:', error);
      }
    },
  },
  mounted() {
    this.fetchAccounts();
  },
};
</script>


<style scoped>
.my-bets-container {
  display: flex;
  flex-direction: column; /* Ajustado para coluna para facilitar a responsividade */
  justify-content: space-between;
  padding: 1rem;
  background-color: #282828;
  min-height: 93vh;
  overflow: hidden;
  align-items: stretch;
}

.bets-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Responsividade usando grid */
  gap: 20px;
  width: 100%;
  padding: 1rem;
  background-color: #282828; /* Cor de fundo para a página */
}

/* Ajustes adicionais para responsividade */
@media (max-width: 768px) {
  .my-bets-container {
    padding: 0.5rem;
  }
  .bets-content {
    gap: 10px;
    padding: 0.5rem;
  }
}
</style>
