<template>
  <DaleHeader />
  <div class="account-dashboard">
    <DashBoardBalance 
      :total-balance="totalBalance" 
      :average-balance="averageBalance" 
      :total-monthly-withdrawal="totalMonthlyWithdrawal" 
      :average-annual-withdrawal="averageAnnualWithdrawal" 
      :total-annual-deposits="totalAnnualDeposits"
      :average-monthly-deposits="averageMonthlyDeposits"
    />
    <div class="dashboard">
      <div class="dashboard-section">
        <h2>Resumo Saldo</h2>
        <BalanceCardTable />
      </div>
      <div class="dashboard-section">
        <h2>Resumo Saque</h2>
        <WithdrawCardTable />
      </div>
      <div class="dashboard-section">
        <h2>Ranking de Saldo dos Usuários</h2>
        <TopBalancesRanking :users="topBalances" :has-permission="true" />
      </div>
    </div>
    <button class="logout-button" @click="handleLogout">Sair</button>
  </div>
</template>

<script>
import DaleHeader from '@/components/Header.vue';
import DashBoardBalance from '@/components/Account/DashBoardBalance.vue';
import BalanceCardTable from '@/components/Account/BalanceCardTable.vue';
import WithdrawCardTable from '@/components/Account/WithdrawCardTable.vue';
import TopBalancesRanking from '@/components/Account/TopBalancesRanking.vue';
import {
  getDailySummary,
  getMonthlyWithdrawals,
  getAnnualDeposits,
  getUserBalanceRanking
} from '@/services/accountsService';

export default {
  components: {
    DaleHeader,
    DashBoardBalance,
    BalanceCardTable,
    WithdrawCardTable,
    TopBalancesRanking
  },
  data() {
    return {
      totalBalance: 0,
      averageBalance: 0,
      totalMonthlyWithdrawal: 0,
      averageAnnualWithdrawal: 0,
      totalAnnualDeposits: 0,
      averageMonthlyDeposits: 0,
      topBalances: [],
      yearFilter: new Date().getFullYear(),
      monthFilter: new Date().getMonth() + 1
    };
  },
  methods: {
    fetchMonthlyBalances() {
      const month = new Date(Date.parse(this.monthFilter + " 1, 2020")).getMonth() + 1;
      const year = this.yearFilter;

      getDailySummary(month, year)
        .then(data => {
          this.totalBalance = data.total_balance;
          this.averageBalance = data.average_balance;
        })
        .catch(error => {
          console.error("Erro ao buscar dados de saldo mensal:", error);
        });
    },
    fetchMonthlyWithdrawals() {
      const year = this.yearFilter;
      const month = this.monthFilter;

      getMonthlyWithdrawals(year, month)
        .then(data => {
          this.totalMonthlyWithdrawal = data.total_monthly_withdrawal;
          this.averageAnnualWithdrawal = data.average_annual_withdrawal;
        })
        .catch(error => {
          console.error("Erro ao buscar dados de saques mensais:", error);
        });
    },
    fetchAnnualDeposits() {
      const year = this.yearFilter;

      getAnnualDeposits(year)
        .then(data => {
          this.totalAnnualDeposits = data.total_annual_deposits;
          this.averageMonthlyDeposits = data.average_monthly_deposits;
        })
        .catch(error => {
          console.error("Erro ao buscar dados de depósitos anuais:", error);
        });
    },
    fetchUserBalances() {
      getUserBalanceRanking()
        .then(data => {
          this.topBalances = data;
        })
        .catch(error => {
          console.error("Erro ao buscar ranking de saldos dos usuários:", error);
        });
    },
    handleLogout() {
      localStorage.removeItem('userToken');
      localStorage.removeItem('userLoggedIn');
      localStorage.removeItem('username');
      localStorage.removeItem('botPermissions');
      this.$router.push('/login');
    }
  },
  mounted() {
    this.fetchMonthlyBalances();
    this.fetchMonthlyWithdrawals();
    this.fetchAnnualDeposits();
    this.fetchUserBalances();
  }
};
</script>

<style scoped>
.account-dashboard {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: #282828;
  min-height: 93vh;
  overflow: hidden;
  align-items: stretch;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}

.dashboard {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
  background-color: #282828;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  align-items: stretch;
}

.dashboard-section {
  background-color: #383838;
  padding: 20px;
  border-radius: 8px;
  color: #ccc;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, background-color 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.dashboard-section:hover {
  transform: translateY(-5px);
  background-color: #4a4a4a;
}

h2 {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 1.5rem;
  color: #fff;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1px;
  font-weight: 600;
  text-align: center; /* Alinhamento centralizado para todos os h2 */
}

.value {
  font-size: 1.5rem;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  color: #fff;
  margin: 5px 0;
}

.label {
  font-size: 1rem;
  color: #bbb;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
}

.label i {
  margin-right: 5px;
  font-size: 1rem;
  color: #26ffbe;
}

.logout-button {
  position: absolute;
  bottom: 10px;
  right: 20px;
  background: none;
  border: none;
  color: red;
  cursor: pointer;
  font-size: 0.9rem;
  padding: 5px 10px;
  border-radius: 5px;
  opacity: 0.6;
  transition: opacity 0.3s ease, color 0.3s ease;
}

.logout-button:hover {
  opacity: 1;
  color: #ff4d4d;
  font-weight: bold;
}

@media (max-width: 1200px) {
  .dashboard {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 768px) {
  .dashboard {
    grid-template-columns: 1fr;
  }
  
  .dashboard-section {
    width: 100%;
    overflow-x: auto;
  }

  table {
    width: 100%;
    display: block;
    overflow-x: auto;
  }
  
  th, td {
    white-space: nowrap;
  }
}
</style>
