const API_BASE_URL = 'https://api.dale365.com/api/v1/matches';

async function fetchFromApi(endpoint, options = {}) {
  const url = `${API_BASE_URL}${endpoint}`;
  const defaultHeaders = {
    'Content-Type': 'application/json',
    'Authorization': `Token ${localStorage.getItem('userToken')}`
  };

  const config = {
    ...options,
    headers: {
      ...defaultHeaders,
      ...options.headers
    }
  };

  try {
    const response = await fetch(url, config);
    if (response.status === 401) {
      const data = await response.json();
      if (data.detail === 'Invalid token.') {
        // Deslogar usuário e redirecionar para a página de login
        localStorage.removeItem('userToken');
        localStorage.setItem('userLoggedIn', 'false');
        window.location.href = '/login';
      }
    }
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Erro desconhecido');
    }
    // If the response status is 204 (No Content), return null
    if (response.status === 204) {
      return null;
    }
    return await response.json();
  } catch (error) {
    console.error('Erro ao fazer requisição para a API:', error);
    throw error;
  }
}

export function fetchMatches(page = 1, searchTerm = '') {
  return fetchFromApi(`/?page=${page}&search=${encodeURIComponent(searchTerm)}`);
}

export function fetchDraws(page = 1, searchTerm = '') {
  return fetchFromApi(`/draws/?page=${page}&search=${encodeURIComponent(searchTerm)}`);
}

export function fetchDale(botId, page = 1, searchTerm = '') {
  return fetchFromApi(`/dale/${botId}/?page=${page}&search=${encodeURIComponent(searchTerm)}`);
}

export function fetchLastDays(botId) {
  return fetchFromApi(`/last-days/?id=${botId}`);
}

export function fetchLiveMatches() {
  return fetchFromApi('/live/');
}

export function fetchNextMatches() {
  return fetchFromApi('/next/');
}
