<template>
  <div class="bar-chart-card">
    <h3 class="bar-chart-title">{{ title }}</h3>
    <div class="chart-container" v-if="chartData.length > 0">
      <div class="chart">
        <div v-for="entry in chartData" :key="entry.date" class="chart-entry">
          <div class="bar-container">
            <div v-if="entry.green > 0" class="bar green-bar" :style="{ height: getBarHeight(entry, 'green') + '%', bottom: getGreenBarBottom(entry) + '%' }">
              <span class="bar-number green-number">{{ entry.green }}</span>
            </div>
            <div v-if="entry.red > 0" class="bar red-bar" :style="{ height: getBarHeight(entry, 'red') + '%' }">
              <span class="bar-number red-number">{{ entry.red }}</span>
            </div>
          </div>
          <span class="chart-date">{{ formatDate(entry.date) }}</span>
        </div>
      </div>
    </div>
    <div v-else class="no-result">Não há dados disponíveis.</div>
  </div>
</template>


<script>
import { fetchLastDays } from '@/services/matchesService';

export default {
  name: 'BarChartCard',
  props: {
    title: String,
    botId: String
  },
  data() {
    return {
      chartData: [],
      borderRadius: '5px',
      intervalId: null,
      maxSum: 0
    };
  },
  mounted() {
    this.fetchChartData();
    this.intervalId = setInterval(this.fetchChartData, 600000);
  },
  unmounted() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  methods: {
    async fetchChartData() {
      try {
        const data = await fetchLastDays(this.botId);
        this.chartData = data.map(entry => ({
          date: entry.date,
          green: entry.green,
          red: entry.red,
        }));
        this.calculateMaxSum();
      } catch (error) {
        console.error('Erro ao buscar os dados do gráfico:', error);
      }
    },
    calculateMaxSum() {
      this.maxSum = Math.max(...this.chartData.map(entry => entry.green + entry.red));
    },
    getBarHeight(entry, color) {
      const proportion = (entry[color] / this.maxSum) * 100;
      return proportion.toFixed(2);
    },
    getGreenBarBottom(entry) {
      if (entry.red > 0) {
        return (entry.red / this.maxSum) * 100;
      }
      return 0;
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      if (isNaN(date)) {
        console.error('Invalid date:', dateString);
        return dateString; // Retorna a string original se a data for inválida
      }
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() retorna 0-11
      return `${day}-${month}`;
    },
  },
};
</script>



<style scoped>
.bar-chart-card {
  background-color: #383838;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  color: var(--color-text);
  overflow: hidden;
  width: 30rem; /* Ajusta para ocupar 100% da largura do contêiner pai */
  height: 45vh;
  max-width: 100%; /* Remove limite máximo de largura para respeitar a largura do contêiner pai */
  max-height: 70vh; /* Mantém limite máximo de altura para evitar gráficos muito altos */
}

.bar-chart-title {
  text-align: center;
  margin-bottom: 0.5rem;
}

.chart-container {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow-x: auto;
  overflow-y: hidden;
}

.chart {
  display: flex;
  justify-content: space-around;
  width: 100%; /* Ajusta para ocupar 100% da largura do contêiner pai */
}

.chart-entry {
  width: 1rem;
  margin: 3px;
  border-radius: 5px;
}

.bar-container {
  height: 16rem;
  position: relative;
}

.bar {
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  color: #ccc;
  font-size: 0.8rem;
}

.bar-number {
  position: absolute;
  width: 100%;
  text-align: center;
  transition: opacity 0.3s ease;
  opacity: 1;
  top: 60%;
  transform: translateY(-50%);
}

.green-number {
  color: #ccc;
}

.red-number {
  color: #ccc;
}

.green-bar,
.red-bar {
  transition: height 0.3s ease;
}

.green-bar {
  background-color: #27ae60;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.red-bar {
  background-color: #e74c3c;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.chart-date {
  color: #7f8c8d;
  font-size: 0.6rem;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  margin-top: 5px;
  align-self: flex-start;
}

.no-results {
  color: #ccc;
  text-align: center;
  padding: 20px;
}

@media (max-width: 768px) {
  .bar-container {
    height: 12rem;
  }
  .chart-date {
    font-size: 0.5rem;
  }
}

@media (max-width: 480px) {
  .bar-container {
    height: 10rem;
  }
  .chart-date {
    font-size: 0.4rem;
  }
}
</style>


