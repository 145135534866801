import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import './index.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'vue-toastification/dist/index.css';
import Toast, { POSITION } from 'vue-toastification';
import { createVuetify } from 'vuetify';
import 'vuetify/styles';
import '@mdi/font/css/materialdesignicons.css';

const options = {
  position: POSITION.TOP_RIGHT,
  timeout: 3000,
  hideProgressBar: true,
};

const vuetify = createVuetify();

createApp(App)
  .use(store)
  .use(Toast, options)
  .use(router)
  .use(vuetify)
  .mount('#app');
