<template>
  <div class="balance-container">
    <div class="balance-form">
      <form @submit.prevent="processBalance">
        <div class="form-group">
          <v-select
            :options="activeAccountNames"
            v-model="balanceInfo.accountName"
            label="name"
            class="input-field"
            placeholder="Escolha sua conta"
            :searchable="false"
            @change="updateSelectedBalances">
          </v-select>
        </div>
        <!-- Dia do Saldo -->
        <div class="form-group">
          <input
            type="date" 
            id="balanceDate" 
            v-model="balanceInfo.date"
            class="input-field" 
            placeholder="Escolher dia"
          />
          <label for="balanceDate" :class="{ 'float-label': balanceInfo.date }"></label>
        </div>
        <!-- Valor do Saldo -->
        <div class="form-group">
          <input type="number" 
                 id="balanceValue" 
                 v-model="balanceInfo.value" 
                 placeholder="Saldo final do dia" 
                 class="input-field"/>
        </div>
        <div class="button-container">
          <button class="submit-button">Enviar Saldo</button>
        </div>
      </form>
    </div>
    <div class="balance-list" v-if="selectedBalances && selectedBalances.length > 0">
      <h3>Últimos dias:</h3>
      <ul>
        <li v-for="balance in selectedBalances" :key="balance.id">
          {{ formatCurrency(balance.amount) }} - Dia: {{ balance.date }}
          <span class="delete-icon" @click="deleteBalance(balance.id)">×</span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import 'vue3-select/dist/vue3-select.css';
import VSelect from 'vue3-select';
import { useToast } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import { createAccountBalance, deleteAccountBalance, getAccountBalances } from '@/services/bettingService';

export default {
  name: 'BalanceCard',
  props: ['accountNames'],
  components: {
    'v-select': VSelect,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      balanceInfo: {
        accountName: '',
        value: '',
        date: this.getCurrentDate(),
      },
      selectedBalances: [],
    };
  },
  computed: {
    activeAccountNames() {
      return this.accountNames.filter(account => account.active);
    }
  },
  watch: {
    'balanceInfo.accountName'(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updateSelectedBalances();
      }
    },
  },
  methods: {
    async processBalance() {
      try {
        const requestData = this.getRequestData();
        console.log('Request Data:', requestData);  // Log para verificar o payload
        await createAccountBalance(requestData);
        this.toast.success("Saldo do dia atualizado!");
        this.resetFormData();
        this.updateSelectedBalances(); // Atualiza os saldos após adicionar um novo
      } catch (error) {
        console.error(error);  // Verificação do erro
        const errorMessage = error.message || "Erro ao atualizar o saldo. Tente novamente.";
        this.toast.error(errorMessage);
      }
    },
    getRequestData() {
      return {
        user: localStorage.getItem('username'), 
        account: this.balanceInfo.accountName.id,
        amount: this.balanceInfo.value,
        date: this.balanceInfo.date,
      };
    },
    async updateSelectedBalances() {
      const selectedAccountName = this.balanceInfo.accountName?.name || this.balanceInfo.accountName || '';
      const selectedAccount = this.accountNames.find(account => account.name === selectedAccountName);
      if (selectedAccount) {
        const accountData = await getAccountBalances(selectedAccount.id); // Método para pegar os saldos da conta
        this.selectedBalances = accountData.balances || [];
        
        // Ordena os balances pela data
        const sortedBalances = this.selectedBalances.sort((a, b) => new Date(b.date) - new Date(a.date));
        
        // Pega a data do balance mais recente e adiciona um dia
        if (sortedBalances.length > 0) {
          const lastBalanceDate = new Date(sortedBalances[0].date);
          lastBalanceDate.setDate(lastBalanceDate.getDate() + 1);
          this.balanceInfo.date = lastBalanceDate.toISOString().split('T')[0];
        } else {
          this.balanceInfo.date = this.getCurrentDate();
        }
      } else {
        this.selectedBalances = [];
        this.balanceInfo.date = this.getCurrentDate();
      }
    },
    async deleteBalance(id) {
      try {
        await deleteAccountBalance(id);
        this.selectedBalances = this.selectedBalances.filter(balance => balance.id !== id);
        this.toast.success("Saldo deletado com sucesso!");
        this.updateSelectedBalances(); // Atualiza os saldos após deletar
      } catch (error) {
        console.error(error);  // Verificação do erro
        const errorMessage = error.message || "Erro ao deletar o saldo. Tente novamente.";
        this.toast.error(errorMessage);
      }
    },
    getCurrentDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, '0');
      const day = today.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    resetFormData() {
      this.balanceInfo = {
        accountName: '',
        value: '',
      };
    },
    formatCurrency(amount) {
      return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(amount);
    },
  }
};
</script>



<style scoped>
/* Estilos do formulário */
.balance-container {
  background-color: #2b2b2b; /* Cor de fundo */
  border-radius: 10px; /* Bordas arredondadas */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra */
  padding: 20px;
  margin: 20px;
  max-height: 100vh;
  font-size: 1rem;
  color: #e0e0e0; /* Texto claro */
  overflow: hidden;
  font-family: 'Roboto', sans-serif; /* Fonte Roboto */
}

.balance-form {
  background-color: #3a3a3a; /* Cor de fundo do formulário */
  padding: 20px;
  border-radius: 10px; /* Bordas arredondadas */
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px; /* Espaçamento entre os campos */
  display: flex; /* Alinhamento horizontal */
  flex-direction: column; /* Alinhamento vertical */
}

.form-group label {
  color: #b0b0b0; /* Cor do texto dos labels */
  margin-bottom: 5px;
  font-weight: 500; /* Peso da fonte */
}

/* Estilos para os campos de entrada */
.form-group input,
.form-group select,
.form-group textarea {
  background-color: #f5f5f5; /* Cor de fundo */
  border: 1px solid #ccc; /* Cor da borda */
  border-radius: 5px; /* Bordas arredondadas */
  padding: 10px;
  font-size: 1rem;
  color: #333; /* Cor do texto */
  transition: all 0.3s ease;
  width: 100%;
  box-sizing: border-box;
}

.form-group input:focus {
  outline: none;
  box-shadow: 0 0 5px 1px #007bff; /* Sombra ao focar */
}

/* Estilos para o botão de processar */
.submit-button {
  background-color: #007bff; /* Cor de fundo */
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 6px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  transition: all 0.3s ease;
  margin-top: 10px;
}

.submit-button:hover {
  background-color: #0056b3; /* Cor ao passar o mouse */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px); /* Efeito de elevação ao passar o mouse */
}

.submit-button:active {
  background-color: #004085; /* Cor ao clicar */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transform: translateY(0); /* Reduz o efeito de elevação ao clicar */
}

.input-field.vs--single {
  background-color: #f5f5f5; /* Light gray background */
  border: 1px solid #ccc; /* Light gray border */
  border-radius: 5px; /* Bordas arredondadas conforme seu estilo */
  font-size: 1rem; /* Tamanho de fonte conforme definido */
  color: #333; /* Dark gray text color */
  transition: all 0.3s ease; /* Animation transition */
  box-sizing: border-box; /* Include padding and border in width calculation */
  flex:1;
}

.input-field .vs__search {
  background-color: transparent; /* Faz o input interno ter fundo transparente */
  color: #333; /* Cor do texto para o input de pesquisa */
}

/* Ajustando o estilo para a opção selecionada */
.input-field .vs__selected {
  color: #333; /* Cor do texto para o item selecionado */
}

/* Ajustes para os botões de ação do v-select */
.input-field .vs__clear,
.input-field .vs__open-indicator {
  fill: #888; /* Cor dos ícones SVG dentro dos botões */
}

.input-field .vs__dropdown-toggle:hover .vs__open-indicator,
.input-field .vs__dropdown-toggle:hover .vs__clear {
  fill: #555; /* Escurecer ícones ao passar o mouse */
}

.input-field .vs__dropdown-toggle:active .vs__open-indicator,
.input-field .vs__dropdown-toggle:active .vs__clear {
  fill: #333; /* Ícones mais escuros ao pressionar */
}

.balance-list {
  background-color: #3a3a3a; /* Cor de fundo */
  padding: 20px;
  border-radius: 10px; /* Bordas arredondadas */
  margin-top: 20px;
}

.balance-list ul {
  list-style-type: none; /* Remove estilo de lista */
  padding: 0;
}

.balance-list li {
  background-color: #484848; /* Cor de fundo */
  padding: 10px;
  margin-bottom: 10px; /* Espaçamento entre itens */
  border-radius: 5px; /* Bordas arredondadas */
  color: #e0e0e0; /* Cor do texto */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Sombra */
}

.delete-icon {
  cursor: pointer;
  padding: 0 10px;
  font-size: 1.2rem; /* Tamanho do ícone */
  color: #ff0000; /* Cor vermelha */
}
.button-container {
  display: flex;
  justify-content: center; /* Centraliza o botão horizontalmente */
}
h3 {
  margin-bottom: 10px;
  font-weight: 500; /* Peso da fonte */
}
</style>
