<template>
  <div class="top-players-card">
    <h3 class="top-players-title">{{ title }}</h3>
    <div v-if="players && players.length" class="players-list-container">
      <ul class="players-list">
        <li v-for="player in players" :key="player.player_name" class="player">
          <span class="player-name">{{ player.player_name }}</span>
          <div class="player-bar-chart">
            <div class="player-green" :style="{ width: (player.greens / (player.greens + player.reds)) * 100 + '%' }">
              <span class="bar-number" :class="{ 'outside': (player.greens / (player.greens + player.reds)) * 100 < threshold }">{{ player.greens }}</span>
            </div>
            <div class="player-red" :style="{ width: (player.reds / (player.greens + player.reds)) * 100 + '%' }">
              <span class="bar-number" :class="{ 'outside': (player.reds / (player.greens + player.reds)) * 100 < threshold }">{{ player.reds }}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div v-else class="no-results">
      Não há dados disponíveis.
    </div>
  </div>
</template>


<script>
import { fetchTopPlayers } from '@/services/rankingService';

export default {
  name: 'TopPlayersCard',
  props: {
    title: String,
    rankingType: String // Nova prop para definir o tipo de ranking
  },
  data() {
    return {
      threshold: 1,
      players: [],
      intervalId: null // Armazena o ID do interval para limpeza posterior
    };
  },
  mounted() {
    this.fetchTopPlayers();
    this.intervalId = setInterval(this.fetchTopPlayers, 600000);
  },
  unmounted() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  methods: {
    async fetchTopPlayers() {
      try {
        const data = await fetchTopPlayers(this.rankingType);
        if (data && this.rankingType) {
          this.players = data[this.rankingType];
        }
      } catch (error) {
        console.error('Erro ao buscar os jogadores:', error);
      }
    }
  }
};
</script>

<style scoped>
.top-players-card {
  background-color: #383838;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  color: white;
  height: 45vh;
  width: 99%;
  min-width: 15rem;
  display: flex;
  flex-direction: column;
}

.top-players-title {
  color: var(--color-text);
  text-align: center;
  margin-bottom: 1rem;
}

.players-list-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.players-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.player {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.player-name {
  flex-shrink: 0;
  margin-right: 1rem;
  color: #7f8c8d;
}

.player-bar-chart {
  flex-grow: 1;
  height: 20px;
  background-color: #34495e;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  display: flex;
}

.player-green {
  height: 100%;
  background-color: #27ae60;
  position: relative;
  transition: width 0.3s ease;
}

.player-red {
  height: 100%;
  background-color: #e74c3c;
  position: relative;
  transition: width 0.3s ease;
}

.bar-number {
  position: absolute;
  width: 100%;
  text-align: center;
  transition: opacity 0.3s ease;
  color: #ccc;
  font-size: 0.8rem;
  opacity: 1;
  z-index: 2;
}

.outside {
  position: absolute;
  right: 100%;
  color: #27ae60;
  margin-right: 5px;
}

.player-red .outside {
  left: 100%;
  color: #e74c3c;
  margin-left: 5px;
}

.no-results {
  color: #ccc;
  text-align: center;
  padding: 20px;
}
</style>
