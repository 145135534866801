import { createRouter, createWebHistory } from 'vue-router';
import Login from '../views/Login.vue';
import TodasPartidas from '../views/Games.vue';
import AoVivo from '../views/Live.vue';
import MinhasApostas from '../views/MyBets.vue';
import Conta from '../views/AccountDashboard.vue';
import Register from '../views/Register.vue';

// Função simulada para verificar se o usuário está logado
function isLoggedIn() {
  // Substitua essa lógica pela verificação real do estado de login do seu usuário
  return localStorage.getItem('userLoggedIn') === 'true';
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/login',
      component: Login
    },
    {
      path: '/register',
      component: Register
    },
    {
      path: '/partidas',
      component: TodasPartidas,
      meta: { requiresAuth: true }
    },
    {
      path: '/ao-vivo',
      component: AoVivo,
      meta: { requiresAuth: true }
    },
    {
      path: '/apostas',
      component: MinhasApostas,
      meta: { requiresAuth: true }
    },
    {
      path: '/conta',
      component: Conta,
      meta: { requiresAuth: true }
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: () => {
        // Funciona como uma função que decide para onde redirecionar
        return isLoggedIn() ? '/partidas' : '/login';
      }
    }
    // Adicione outras rotas aqui
  ],
});

router.beforeEach((to, from, next) => {
  // Verifica se a rota requer autenticação e o usuário não está logado
  if (to.meta.requiresAuth && !isLoggedIn()) {
    next({ path: '/login' }); // Redireciona para a página de Login
  } else if (!to.meta.requiresAuth && isLoggedIn() && to.path === '/login') {
    next({ path: '/partidas' }); // Se o usuário está logado e tenta acessar /login, redireciona para /partidas
  } else {
    next(); // Caso contrário, continua a navegação normalmente
  }
});


export default router;
