import { createStore } from 'vuex';

export default createStore({
  state: {
    user: null,
  },
  mutations: {
    SET_USER(state, userData) {
      state.user = userData;
    },
  },
  actions: {
    async login({ commit }, { username }) {
        const userData = { username }; // Mock de dados do usuário
        commit('SET_USER', userData);
        return userData;
    },
  },
});
