<template>
  <div class="withdraw-container">
    <div class="withdraw-form">
      <form @submit.prevent="processWithdraw">
        <div class="form-group">
          <v-select
            :options="activeAccountNames"
            v-model="withdrawInfo.accountName"
            label="name"
            class="input-field"
            placeholder="Escolha sua conta"
            :searchable="false"
            @change="updateSelectedWithdraws">
          </v-select>
        </div>
        <!-- Data do Saque -->
        <div class="form-group">
          <input
            type="date" 
            id="withdrawDate" 
            v-model="withdrawInfo.date"
            class="input-field" 
            placeholder="Escolher dia"
          />
          <label for="withdrawDate" :class="{ 'float-label': withdrawInfo.date }"></label>
        </div>
        <!-- Valor do Saque -->
        <div class="form-group">
          <input type="number" 
                 id="withdrawValue" 
                 v-model="withdrawInfo.value" 
                 placeholder="Valor do Saque" 
                 class="input-field"/>
        </div>
        <!-- Checkbox para Pagar % da Conta -->
        <div class="checkbox-group">
          <input type="checkbox" 
                 id="payPercentage" 
                 v-model="withdrawInfo.payPercentage" 
                 class="checkbox"
                 @change="togglePercentageInput"/>
          <label for="payPercentage" class="checkbox-label">Vai pagar % para o dono da conta?</label>
        </div>
        <!-- Input para Porcentagem da Conta -->
        <div class="form-group" v-if="withdrawInfo.payPercentage">
          <input type="number" 
                 id="accountPercentage" 
                 v-model="withdrawInfo.accountPercentage" 
                 placeholder="% do dono da Conta" 
                 class="input-field"/>
        </div>
        <!-- Campo para exibir o valor total a pagar -->
        <div class="form-group" v-if="withdrawInfo.payPercentage">
          <input type="text"
           id="totalPayable"
           :value="totalPayable"
           placeholder="Valor do dono da conta"
           class="input-field"
           readonly />
        </div>
        <!-- Taxa do Grupo -->
        <div class="form-group">
          <input type="text"
           id="groupFee"
           :value="withdrawInfo.value ? calculateGroupFee(withdrawInfo.value) : ''"
           placeholder="Valor da taxa do grupo"
           class="input-field"
           readonly />
        </div>
        <div class="button-container">
          <button class="submit-button">Enviar Saque</button>
        </div>
      </form>
    </div>
    <div v-if="errorMessage" class="error-message">
      {{ errorMessage }}
    </div>
    <div class="withdraw-list" v-if="selectedWithdraws.length > 0">
      <h3>Últimos saques:</h3>
      <ul>
        <li v-for="withdraw in selectedWithdraws" :key="withdraw.date">
          {{ formatCurrency(withdraw.amount_calculated) }} - Dia: {{ withdraw.date }}
        </li>
      </ul>
    </div>
  </div>
</template>


<script>
import 'vue3-select/dist/vue3-select.css';
import VSelect from 'vue3-select';
import { useToast } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import { createWithdraw, deleteWithdraw, getAccountWithdraws } from '@/services/bettingService';

export default {
  name: 'WithdrawCard',
  props: ['accountNames'],
  components: {
    'v-select': VSelect,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      totalPayable: '',
      withdrawInfo: {
        accountName: '',
        value: '',
        payPercentage: false,
        accountPercentage: '',
        date: this.getCurrentDate(),
      },
      selectedWithdraws: [],
      selectedAccount: null,
    };
  },
  computed: {
    activeAccountNames() {
      return this.accountNames.filter(account => account.active);
    }
  },
  watch: {
    'withdrawInfo.accountName'(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updateSelectedWithdraws();
      }
    },
    'withdrawInfo.value'() {
      this.calculateTotalPayable();
    },
    'withdrawInfo.accountPercentage'() {
      this.calculateTotalPayable();
    },
  },
  methods: {
    getCurrentDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, '0');
      const day = today.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    async processWithdraw() {
      try {
        const requestData = this.getRequestData();
        const accountId = this.withdrawInfo.accountName.id;
        console.log('Account ID:', accountId); // Log para verificar o accountId
        if (!accountId) {
          throw new Error('Account ID is missing');
        }
        await createWithdraw(accountId, requestData);
        this.toast.success("Saque processado com sucesso!");
        this.resetFormData();
        this.updateSelectedWithdraws();
      } catch (error) {
        console.error(error);
        this.toast.error("Erro ao processar o saque. Tente novamente.");
      }
    },
    getRequestData() {
      const taxGroup = this.calculateGroupFee(this.withdrawInfo.value);
      return {
        user: localStorage.getItem('username'),
        account: this.withdrawInfo.accountName.id,
        amount: this.withdrawInfo.value,
        tax_owner_account: this.withdrawInfo.accountPercentage ? this.withdrawInfo.accountPercentage : 0,
        amount_owner_account: this.totalPayable ? this.totalPayable : 0,
        amount_group: taxGroup,
        date: this.withdrawInfo.date,
      };
    },
    togglePercentageInput() {
      if (!this.withdrawInfo.payPercentage) {
        this.withdrawInfo.accountPercentage = '';
      }
    },
    async updateSelectedWithdraws() {
      const selectedAccountName = this.withdrawInfo.accountName?.name || this.withdrawInfo.accountName || '';
      const selectedAccount = this.accountNames.find(account => account.name === selectedAccountName);
      if (selectedAccount) {
        const accountData = await getAccountWithdraws(selectedAccount.id);
        this.selectedAccount = accountData;
        this.selectedWithdraws = accountData.withdraws || [];
      } else {
        this.selectedWithdraws = [];
      }
    },
    async deleteWithdraw(id) {
      try {
        await deleteWithdraw(id);
        this.selectedWithdraws = this.selectedWithdraws.filter(withdraw => withdraw.id !== id);
        this.toast.success("Saque deletado com sucesso!");
        this.updateSelectedWithdraws();
      } catch (error) {
        console.error(error);
        this.toast.error("Erro ao deletar o saque. Tente novamente.");
      }
    },
    resetFormData() {
      this.withdrawInfo = {
        accountName: '',
        value: '',
        payPercentage: false,
        accountPercentage: '',
        date: this.getCurrentDate(),
      };
    },
    formatCurrency(amount) {
      return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(amount);
    },
    calculateGroupFee(value) {
      if (this.selectedAccount) {
        const taxGroup = parseFloat(this.selectedAccount.tax_group) / 100;
        return (value * taxGroup).toFixed(2);
      }
      return (value * 0.2).toFixed(2); // Default to 20% if tax_group is not available
    },
    calculateTotalPayable() {
      if (this.withdrawInfo.value && this.withdrawInfo.accountPercentage) {
        const percentageAmount =
          (parseFloat(this.withdrawInfo.value) *
            (parseFloat(this.withdrawInfo.accountPercentage) / 100))
            .toFixed(2);
        this.totalPayable = percentageAmount;
      } else {
        this.totalPayable = '';
      }
    },
  },
};
</script>

<style scoped>
.withdraw-container {
  background-color: #2b2b2b; /* Cor de fundo */
  border-radius: 10px; /* Bordas arredondadas */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra */
  padding: 20px;
  margin: 20px;
  max-height: 100vh;
  font-size: 1rem;
  color: #e0e0e0; /* Texto claro */
  overflow: hidden;
  font-family: 'Roboto', sans-serif; /* Fonte Roboto */
}

.withdraw-form {
  background-color: #3a3a3a; /* Cor de fundo do formulário */
  padding: 20px;
  border-radius: 10px; /* Bordas arredondadas */
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px; /* Espaçamento entre os campos */
  display: flex; /* Alinhamento horizontal */
  flex-direction: column; /* Alinhamento vertical */
}

.form-group label {
  color: #b0b0b0; /* Cor do texto dos labels */
  margin-bottom: 5px;
  font-weight: 500; /* Peso da fonte */
}

/* Estilos para os campos de entrada */
.form-group input,
.form-group select,
.form-group textarea {
  background-color: #f5f5f5; /* Cor de fundo */
  border: 1px solid #ccc; /* Cor da borda */
  border-radius: 5px; /* Bordas arredondadas */
  padding: 10px;
  font-size: 1rem;
  color: #333; /* Cor do texto */
  transition: all 0.3s ease;
  width: 100%;
  box-sizing: border-box;
}

.form-group input:focus {
  outline: none;
  box-shadow: 0 0 5px 1px #007bff; /* Sombra ao focar */
}

/* Estilos para o botão de processar */
.submit-button {
  background-color: #007bff; /* Cor de fundo */
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 6px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  transition: all 0.3s ease;
  margin-top: 10px;
}

.submit-button:hover {
  background-color: #0056b3; /* Cor ao passar o mouse */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px); /* Efeito de elevação ao passar o mouse */
}

.submit-button:active {
  background-color: #004085; /* Cor ao clicar */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transform: translateY(0); /* Reduz o efeito de elevação ao clicar */
}

.input-field.vs--single {
  background-color: #f5f5f5; /* Light gray background */
  border: 1px solid #ccc; /* Light gray border */
  border-radius: 5px; /* Bordas arredondadas conforme seu estilo */
  font-size: 1rem; /* Tamanho de fonte conforme definido */
  color: #333; /* Dark gray text color */
  transition: all 0.3s ease; /* Animation transition */
  box-sizing: border-box; /* Include padding and border in width calculation */
  flex:1;
}

.input-field .vs__search {
  background-color: transparent; /* Faz o input interno ter fundo transparente */
  color: #333; /* Cor do texto para o input de pesquisa */
}

/* Ajustando o estilo para a opção selecionada */
.input-field .vs__selected {
  color: #333; /* Cor do texto para o item selecionado */
}

/* Ajustes para os botões de ação do v-select */
.input-field .vs__clear,
.input-field .vs__open-indicator {
  fill: #888; /* Cor dos ícones SVG dentro dos botões */
}

.input-field .vs__dropdown-toggle:hover .vs__open-indicator,
.input-field .vs__dropdown-toggle:hover .vs__clear {
  fill: #555; /* Escurecer ícones ao passar o mouse */
}

.input-field .vs__dropdown-toggle:active .vs__open-indicator,
.input-field .vs__dropdown-toggle:active .vs__clear {
  fill: #333; /* Ícones mais escuros ao pressionar */
}

.withdraw-list {
  background-color: #3a3a3a; /* Cor de fundo */
  padding: 20px;
  border-radius: 10px; /* Bordas arredondadas */
  margin-top: 20px;
}

.withdraw-list ul {
  list-style-type: none; /* Remove estilo de lista */
  padding: 0;
}
.button-container {
  display: flex;
  justify-content: center; /* Centraliza o botão horizontalmente */
}
.withdraw-list li {
  background-color: #484848; /* Cor de fundo */
  padding: 10px;
  margin-bottom: 10px; /* Espaçamento entre itens */
  border-radius: 5px; /* Bordas arredondadas */
  color: #e0e0e0; /* Cor do texto */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Sombra */
}

h3 {
  margin-bottom: 10px;
  font-weight: 500; /* Peso da fonte */
}
</style>
